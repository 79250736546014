/* Eksempel på 1 font-face fra Nicolai - Høks */

//  @font-face {
//    font-family: 'Haeock'; /* Navn på fonten */
//    font-style: normal; /* Normal eller italic */
//    font-weight: 300; /* Bestemmer tykkelse i hele hundrede mellem 100 & 900 */
//    font-display: swap; /* Måden fonten loades på - skal stå til swap ift. Pagespeed */
//    src: local('Haeock Light'), /* Tjekker om fonten eksisterer på computeren (hurtigste load) (1. prioritet) */
//        url('/style/fonts/Haeock-Light.woff2') format('woff2'), /* Mest optimerede fonttype til meget nye browsere (2. prioritet) */
//        url('/style/fonts/Haeock-Light.woff') format('woff'), /* Anden mest optimerede fonttype til nyere browsere (3. prioritet) */
//        url('/style/fonts/Haeock-Light.otf') format('opentype'), /* Standard gammeldags fontformat ikke optimere til web (4. prioritet) */
//        url('/style/fonts/Haeock-Light.ttf') format('truetype'); /* Standard gammeldags fontformat ikke optimere til web (5. prioritet) */
//  }

// ================
// Playfair Display
// ================
@font-face {
  font-family: 'Playfair Display'; /* Navn på fonten */
  font-style: normal; /* Normal eller italic */
  font-weight: 400; /* Bestemmer tykkelse i hele hundrede mellem 100 & 900 */
  font-display: swap; /* Måden fonten loades på - skal stå til swap ift. Pagespeed */
  src: local('Playfair Display Regular'), /* Tjekker om fonten eksisterer på computeren (hurtigste load) (1. prioritet) */
      url('/style/fonts/PlayfairDisplay-Regular.woff2') format('woff2'), /* Mest optimerede fonttype til meget nye browsere (2. prioritet) */
      url('/style/fonts/PlayfairDisplay-Regular.ttf') format('truetype'), /* Standard gammeldags fontformat ikke optimere til web (5. prioritet) */
}

@font-face {
  font-family: 'Playfair Display'; /* Navn på fonten */
  font-style: italic; /* Normal eller italic */
  font-weight: 400; /* Bestemmer tykkelse i hele hundrede mellem 100 & 900 */
  font-display: swap; /* Måden fonten loades på - skal stå til swap ift. Pagespeed */
  src: local('Playfair Display Italic'), /* Tjekker om fonten eksisterer på computeren (hurtigste load) (1. prioritet) */
      url('/style/fonts/PlayfairDisplay-Italic.woff2') format('woff2'), /* Mest optimerede fonttype til meget nye browsere (2. prioritet) */
      url('/style/fonts/PlayfairDisplay-Italic.ttf') format('truetype'), /* Standard gammeldags fontformat ikke optimere til web (5. prioritet) */
}

@font-face {
  font-family: 'Playfair Display'; /* Navn på fonten */
  font-style: normal; /* Normal eller italic */
  font-weight: 500; /* Bestemmer tykkelse i hele hundrede mellem 100 & 900 */
  font-display: swap; /* Måden fonten loades på - skal stå til swap ift. Pagespeed */
  src: local('Playfair Display Medium'), /* Tjekker om fonten eksisterer på computeren (hurtigste load) (1. prioritet) */
      url('/style/fonts/PlayfairDisplay-Medium.woff2') format('woff2'), /* Mest optimerede fonttype til meget nye browsere (2. prioritet) */
      url('/style/fonts/PlayfairDisplay-Medium.ttf') format('truetype'), /* Standard gammeldags fontformat ikke optimere til web (5. prioritet) */
}

@font-face {
  font-family: 'Playfair Display'; /* Navn på fonten */
  font-style: italic; /* Normal eller italic */
  font-weight: 500; /* Bestemmer tykkelse i hele hundrede mellem 100 & 900 */
  font-display: swap; /* Måden fonten loades på - skal stå til swap ift. Pagespeed */
  src: local('Playfair Display Medium Italic'), /* Tjekker om fonten eksisterer på computeren (hurtigste load) (1. prioritet) */
      url('/style/fonts/PlayfairDisplay-MediumItalic.woff2') format('woff2'), /* Mest optimerede fonttype til meget nye browsere (2. prioritet) */
      url('/style/fonts/PlayfairDisplay-MediumItalic.ttf') format('truetype'), /* Standard gammeldags fontformat ikke optimere til web (5. prioritet) */
}

@font-face {
  font-family: 'Playfair Display'; /* Navn på fonten */
  font-style: normal; /* Normal eller italic */
  font-weight: 600; /* Bestemmer tykkelse i hele hundrede mellem 100 & 900 */
  font-display: swap; /* Måden fonten loades på - skal stå til swap ift. Pagespeed */
  src: local('Playfair Display Semibold'), /* Tjekker om fonten eksisterer på computeren (hurtigste load) (1. prioritet) */
      url('/style/fonts/PlayfairDisplay-SemiBold.woff2') format('woff2'), /* Mest optimerede fonttype til meget nye browsere (2. prioritet) */
      url('/style/fonts/PlayfairDisplay-SemiBold.ttf') format('truetype'), /* Standard gammeldags fontformat ikke optimere til web (5. prioritet) */
}

@font-face {
  font-family: 'Playfair Display'; /* Navn på fonten */
  font-style: italic; /* Normal eller italic */
  font-weight: 600; /* Bestemmer tykkelse i hele hundrede mellem 100 & 900 */
  font-display: swap; /* Måden fonten loades på - skal stå til swap ift. Pagespeed */
  src: local('Playfair Display Semibold Italic'), /* Tjekker om fonten eksisterer på computeren (hurtigste load) (1. prioritet) */
      url('/style/fonts/PlayfairDisplay-SemiBoldItalic.woff2') format('woff2'), /* Mest optimerede fonttype til meget nye browsere (2. prioritet) */
      url('/style/fonts/PlayfairDisplay-SemiBoldItalic.ttf') format('truetype'), /* Standard gammeldags fontformat ikke optimere til web (5. prioritet) */
}

@font-face {
  font-family: 'Playfair Display'; /* Navn på fonten */
  font-style: normal; /* Normal eller italic */
  font-weight: 700; /* Bestemmer tykkelse i hele hundrede mellem 100 & 900 */
  font-display: swap; /* Måden fonten loades på - skal stå til swap ift. Pagespeed */
  src: local('Playfair Display Bold'), /* Tjekker om fonten eksisterer på computeren (hurtigste load) (1. prioritet) */
      url('/style/fonts/PlayfairDisplay-Bold.woff2') format('woff2'), /* Mest optimerede fonttype til meget nye browsere (2. prioritet) */
      url('/style/fonts/PlayfairDisplay-Bold.ttf') format('truetype'), /* Standard gammeldags fontformat ikke optimere til web (5. prioritet) */
}

@font-face {
  font-family: 'Playfair Display'; /* Navn på fonten */
  font-style: italic; /* Normal eller italic */
  font-weight: 700; /* Bestemmer tykkelse i hele hundrede mellem 100 & 900 */
  font-display: swap; /* Måden fonten loades på - skal stå til swap ift. Pagespeed */
  src: local('Playfair Display Bold Italic'), /* Tjekker om fonten eksisterer på computeren (hurtigste load) (1. prioritet) */
      url('/style/fonts/PlayfairDisplay-BoldItalic.woff2') format('woff2'), /* Mest optimerede fonttype til meget nye browsere (2. prioritet) */
      url('/style/fonts/PlayfairDisplay-BoldItalic.ttf') format('truetype'), /* Standard gammeldags fontformat ikke optimere til web (5. prioritet) */
}

// ============
// Proxima Nova
// ============
@font-face {
  font-family: 'Proxima Nova'; /* Navn på fonten */
  font-style: normal; /* Normal eller italic */
  font-weight: 300; /* Bestemmer tykkelse i hele hundrede mellem 100 & 900 */
  font-display: swap; /* Måden fonten loades på - skal stå til swap ift. Pagespeed */
  src: local('Proxima Nova Light'), /* Tjekker om fonten eksisterer på computeren (hurtigste load) (1. prioritet) */
      url('/style/fonts/ProximaNova-Light.woff2') format('woff2'), /* Mest optimerede fonttype til meget nye browsere (2. prioritet) */
      url('/style/fonts/ProximaNova-Light.otf') format('opentype'), /* Standard gammeldags fontformat ikke optimere til web (4. prioritet) */
}

@font-face {
  font-family: 'Proxima Nova'; /* Navn på fonten */
  font-style: italic; /* Normal eller italic */
  font-weight: 300; /* Bestemmer tykkelse i hele hundrede mellem 100 & 900 */
  font-display: swap; /* Måden fonten loades på - skal stå til swap ift. Pagespeed */
  src: local('Proxima Nova Light Italic'), /* Tjekker om fonten eksisterer på computeren (hurtigste load) (1. prioritet) */
      url('/style/fonts/ProximaNova-LightItalic.woff2') format('woff2'), /* Mest optimerede fonttype til meget nye browsere (2. prioritet) */
      url('/style/fonts/ProximaNova-LightItalic.otf') format('opentype'), /* Standard gammeldags fontformat ikke optimere til web (4. prioritet) */
}

@font-face {
  font-family: 'Proxima Nova'; /* Navn på fonten */
  font-style: normal; /* Normal eller italic */
  font-weight: 400; /* Bestemmer tykkelse i hele hundrede mellem 100 & 900 */
  font-display: swap; /* Måden fonten loades på - skal stå til swap ift. Pagespeed */
  src: local('Proxima Nova Regular'), /* Tjekker om fonten eksisterer på computeren (hurtigste load) (1. prioritet) */
      url('/style/fonts/ProximaNova-Regular.woff2') format('woff2'), /* Mest optimerede fonttype til meget nye browsere (2. prioritet) */
      url('/style/fonts/ProximaNova-Regular.otf') format('opentype'), /* Standard gammeldags fontformat ikke optimere til web (4. prioritet) */
}

@font-face {
  font-family: 'Proxima Nova'; /* Navn på fonten */
  font-style: italic; /* Normal eller italic */
  font-weight: 400; /* Bestemmer tykkelse i hele hundrede mellem 100 & 900 */
  font-display: swap; /* Måden fonten loades på - skal stå til swap ift. Pagespeed */
  src: local('Proxima Nova Italic'), /* Tjekker om fonten eksisterer på computeren (hurtigste load) (1. prioritet) */
      url('/style/fonts/ProximaNova-RegularItalic.woff2') format('woff2'), /* Mest optimerede fonttype til meget nye browsere (2. prioritet) */
      url('/style/fonts/ProximaNova-RegularItalic.otf') format('opentype'), /* Standard gammeldags fontformat ikke optimere til web (4. prioritet) */
}

@font-face {
  font-family: 'Proxima Nova'; /* Navn på fonten */
  font-style: normal; /* Normal eller italic */
  font-weight: 500; /* Bestemmer tykkelse i hele hundrede mellem 100 & 900 */
  font-display: swap; /* Måden fonten loades på - skal stå til swap ift. Pagespeed */
  src: local('Proxima Nova Medium'), /* Tjekker om fonten eksisterer på computeren (hurtigste load) (1. prioritet) */
      url('/style/fonts/ProximaNova-Medium.woff2') format('woff2'), /* Mest optimerede fonttype til meget nye browsere (2. prioritet) */
      url('/style/fonts/ProximaNova-Medium.otf') format('opentype'), /* Standard gammeldags fontformat ikke optimere til web (4. prioritet) */
}

@font-face {
  font-family: 'Proxima Nova'; /* Navn på fonten */
  font-style: italic; /* Normal eller italic */
  font-weight: 500; /* Bestemmer tykkelse i hele hundrede mellem 100 & 900 */
  font-display: swap; /* Måden fonten loades på - skal stå til swap ift. Pagespeed */
  src: local('Proxima Nova Medium Italic'), /* Tjekker om fonten eksisterer på computeren (hurtigste load) (1. prioritet) */
      url('/style/fonts/ProximaNova-MediumItalic.woff2') format('woff2'), /* Mest optimerede fonttype til meget nye browsere (2. prioritet) */
      url('/style/fonts/ProximaNova-MediumItalic.otf') format('opentype'), /* Standard gammeldags fontformat ikke optimere til web (4. prioritet) */
}

@font-face {
  font-family: 'Proxima Nova'; /* Navn på fonten */
  font-style: normal; /* Normal eller italic */
  font-weight: 600; /* Bestemmer tykkelse i hele hundrede mellem 100 & 900 */
  font-display: swap; /* Måden fonten loades på - skal stå til swap ift. Pagespeed */
  src: local('Proxima Nova Semibold'), /* Tjekker om fonten eksisterer på computeren (hurtigste load) (1. prioritet) */
      url('/style/fonts/ProximaNova-Semibold.woff2') format('woff2'), /* Mest optimerede fonttype til meget nye browsere (2. prioritet) */
      url('/style/fonts/ProximaNova-Semibold.otf') format('opentype'), /* Standard gammeldags fontformat ikke optimere til web (4. prioritet) */
}

@font-face {
  font-family: 'Proxima Nova'; /* Navn på fonten */
  font-style: italic; /* Normal eller italic */
  font-weight: 600; /* Bestemmer tykkelse i hele hundrede mellem 100 & 900 */
  font-display: swap; /* Måden fonten loades på - skal stå til swap ift. Pagespeed */
  src: local('Proxima Nova Semibold Italic'), /* Tjekker om fonten eksisterer på computeren (hurtigste load) (1. prioritet) */
      url('/style/fonts/ProximaNova-SemiboldItalic.woff2') format('woff2'), /* Mest optimerede fonttype til meget nye browsere (2. prioritet) */
      url('/style/fonts/ProximaNova-SemiboldItalic.otf') format('opentype'), /* Standard gammeldags fontformat ikke optimere til web (4. prioritet) */
}

@font-face {
  font-family: 'Proxima Nova'; /* Navn på fonten */
  font-style: normal; /* Normal eller italic */
  font-weight: 700; /* Bestemmer tykkelse i hele hundrede mellem 100 & 900 */
  font-display: swap; /* Måden fonten loades på - skal stå til swap ift. Pagespeed */
  src: local('Proxima Nova Bold'), /* Tjekker om fonten eksisterer på computeren (hurtigste load) (1. prioritet) */
      url('/style/fonts/ProximaNova-Bold.woff2') format('woff2'), /* Mest optimerede fonttype til meget nye browsere (2. prioritet) */
      url('/style/fonts/ProximaNova-Bold.otf') format('opentype'), /* Standard gammeldags fontformat ikke optimere til web (4. prioritet) */
}

@font-face {
  font-family: 'Proxima Nova'; /* Navn på fonten */
  font-style: italic; /* Normal eller italic */
  font-weight: 700; /* Bestemmer tykkelse i hele hundrede mellem 100 & 900 */
  font-display: swap; /* Måden fonten loades på - skal stå til swap ift. Pagespeed */
  src: local('Proxima Nova Bold Italic'), /* Tjekker om fonten eksisterer på computeren (hurtigste load) (1. prioritet) */
      url('/style/fonts/ProximaNova-BoldItalic.woff2') format('woff2'), /* Mest optimerede fonttype til meget nye browsere (2. prioritet) */
      url('/style/fonts/ProximaNova-BoldItalic.otf') format('opentype'), /* Standard gammeldags fontformat ikke optimere til web (4. prioritet) */
}
  