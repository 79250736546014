// Remember to add new variables to _root.scss for global variable access.
// Filter color generator: https://codepen.io/sosuke/pen/Pjoqqp

// Berry & Bean Color Scheme
$theme-color-1: #DE2E39;
$theme-color-1a: #E1404A;
$theme-color-1b: #D7282F;
$theme-color-1c: #C42932;
$theme-color-1-filter: invert(37%) sepia(75%) saturate(7471%) hue-rotate(345deg) brightness(93%) contrast(85%);
$theme-color-1c-filter: invert(19%) sepia(79%) saturate(1807%) hue-rotate(334deg) brightness(116%) contrast(101%);
$theme-color-2: #241A1A;
$theme-color-3: #E6EAEB;
$theme-color-4: #EAE7DF;
$theme-color-5: #FBE4D2;
$theme-color-6: #262626;
$theme-color-7: #{$theme-color-3};
$theme-color-8: #D8DCDD;
$theme-color-8-filter: invert(100%) sepia(33%) saturate(375%) hue-rotate(168deg) brightness(92%) contrast(88%);
$theme-color-9: #575757;
$theme-color-10: #121617;
$theme-color-10-gradient: rgba(18, 22, 23, 0.2);
$theme-color-10-overlay: rgba(18, 22, 23, 0.8);
$theme-color-10a: #333333;
$theme-color-10b: #797979;
$theme-color-10c: #B7B9B9;
$theme-color-10d: #D1D1D1;
$theme-color-10e: #E8E8E8;
$theme-color-10f: #FAFAFA;
$theme-color-11: #FFFFFF;

$theme-color-copyright: #{$theme-color-10};

$theme-color-badge-1: #B24310;
$theme-color-badge-2: #36462D;
$theme-color-badge-3: #A59380;
$theme-color-badge-4: #FFFFFF;

$theme-color-product-1: #C24A18;
$theme-color-product-2: #747474;

$theme-color-overlay: rgba(5, 10, 0, 0.47);